@font-face {
    font-family: 'Jost';
    src: url('/assets/fonts/jost.ttf') format("truetype-variations");
    font-weight: 1 999;
    font-display: swap;

  }

// @font-face {
//     font-family: "Jost";
//     src: url("/assets/fonts/Jost-Bold.woff2") format("woff2");
//     font-weight: 700900;
//     font-style: bold;
//   }
// @font-face {
//     font-family: "Jost";
//     src: url("/assets/fonts/Jost-Black.woff2") format("woff2");
//     font-weight: 900;
//     font-style: black;
//   }
// @font-face {
//     font-family: "Jost";
//     src: url("/assets/fonts/Jost-Light.woff2") format("woff2");
//     font-weight: 300;
//   }
// @font-face {
//     font-family: "Jost";
//     src: url("/assets/fonts/Jost-Medium.woff2") format("woff2");
//     font-weight: 500;
//   }
// @font-face {
//     font-family: "Jost";
//     src: url("/assets/fonts/Jost-Regular.woff2") format("woff2");
//     font-weight: 400;
//     font-style: normal;
//   }
// @font-face {
//     font-family: "Jost";
//     src: url("/assets/fonts/Jost-SemiBold.woff2") format("woff2");
//     font-weight: 600;
//   }
// @font-face {
//     font-family: "Jost";
//     src: url("/assets/fonts/Jost-Thin.woff2") format("woff2");
//     font-weight: 100;
//   }




.text-lg{
  font-size: 16pt;
}